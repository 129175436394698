@import 'nprogress.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply relative;
  @apply bg-gray-900;
  @apply text-zephyr-light;
}

#__next {
  @apply h-full;
  @apply w-full;
}

@layer utilities {
  .scale-on-hover {
    @apply transition-transform duration-100 transform hover:scale-105;
  }
}
